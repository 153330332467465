import "../sass/home-page.sass";
import Flashnews from "./Flashnews";
import VisibilityTracker from "./Visibility/VisibilityTracker";

function isPageViewDesktop() {
    return !!document.querySelector(".pageView__desktop");
}

function isDefaultMode() {
    return !!document.querySelector(".homeHeadlinesRow--displayMode-default");
}

window.addEventListener("DOMContentLoaded",  () => {

    new Flashnews(window.FLASHNEWS_ACTIVE_ENDPOINT_URL);
    new VisibilityTracker(".homeRow--XTRA article");

    if (isPageViewDesktop() && isDefaultMode()) {

        const displayHomeHeadlinesAsidePub = () => {
            document.querySelector(".homeRow--doNotMissPosTopPage").classList.remove("display-none");
        };

        const displayHomeHeadlinesAsideEditorsChoice = () => {
            document.querySelector(".homeRow--doNotMissPosTopPage").classList.add("display-none");
            document.querySelector(".homeRow--mainHeadlines-doNotMiss").classList.remove("display-none");
            document.querySelector(".homeHeadlinesRow__adWrapper").classList.add("display-none");
        };

        const adSlot = document.querySelector(".homeHeadlinesRow__adWrapper .adSlot");

        if (!adSlot) {
            displayHomeHeadlinesAsideEditorsChoice();
            return;
        }

        adSlot.addEventListener("rendered", (event) => {
            if (event.detail.displayed) {
                displayHomeHeadlinesAsidePub();
            } else {
                displayHomeHeadlinesAsideEditorsChoice();
            }
        });
    }
});
