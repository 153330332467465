import { getFeatureByName } from "../Feature";

class TrackedArticle  {
    #article;
    #observer;
    #timeoutId;

    constructor(article, observer) {
        this.#article = article;
        this.#observer = observer;
        this.#timeoutId = null;
    }

    getId() {
        return this.#article.dataset.targetId;
    }

    startTrackingVisibility() {
        if (!!this.#timeoutId) {
            return;
        }
        this.toggleVisibilityState("calculating");
        this.setTimeout(
            trackedArticle => {
                window.dispatchEvent(
                    new CustomEvent(
                        "post.viewed",
                        {
                            "detail": {
                                "storyId": trackedArticle.getId(),
                            }
                        }
                    )
                );

                trackedArticle.toggleVisibilityState("shown");
                this.#observer.unobserve(this.#article);
            },
            millisecondsIntersectionVisible,
            this,
        );
    }
    abortTrackingVisibility() {
        this.toggleVisibilityState("none");
        this.clearTimeout();
    }

    setTimeout(handler, timeout, trackedArticle) {
        this.#timeoutId = setTimeout(handler, timeout, trackedArticle);
    }

    clearTimeout() {
        clearTimeout(this.#timeoutId);
        this.#timeoutId = null;
    }

    toggleVisibilityState(state) {
        this.#article.classList.remove("visibility_none");
        this.#article.classList.remove("visibility_calculating");
        this.#article.classList.remove("visibility_shown");

        this.#article.classList.add("visibility_" + state);
    }
}

class TrackedArticleCollection {
    #articleCollection;

    constructor() {
        this.#articleCollection = [];
    }

    add(article) {
        if (!!this.#articleCollection[article.getId()]) {
            console.warn("article %o is already tracked", article.getId());
            return;
        }

        this.#articleCollection[article.getId()] = article;
    }

    removeAll() {
        this.#articleCollection.forEach(article => {
            article.clearTimeout();
        });
    }

    getById(articleId) {
        return this.#articleCollection[articleId] ?? null;
    }
}

const intersectionRatioThreshold = 0.9;
const millisecondsIntersectionVisible = 1000;

export default class VisibilityTracker {

    constructor(selector) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const article = entry.target;
                const trackedArticle = trackedArticleCollection.getById(article.dataset.targetId);
                const intersectionRatio = entry.intersectionRatio;

                if (intersectionRatio >= intersectionRatioThreshold) {
                    trackedArticle.startTrackingVisibility();
                } else {
                    trackedArticle.abortTrackingVisibility();
                }
            });
        }, {
            threshold: [
                intersectionRatioThreshold,
            ],
        });

        const trackedArticleCollection = new TrackedArticleCollection();
        Array
            .from(document.querySelectorAll(selector))
            .forEach((articleElement) => {
                const trackedArticle = new TrackedArticle(articleElement, observer);
                trackedArticle.toggleVisibilityState("none");

                trackedArticleCollection.add(trackedArticle);

                observer.observe(articleElement);
            });

        window.addEventListener("beforeunload", () => {
            trackedArticleCollection.removeAll();
            observer.disconnect();
        });
    }
}

if (getFeatureByName("visibilityTracker.debug").enabled) {
    var styleSheet = document.createElement("style");
    styleSheet.innerText = `
    article.storyCard.visibility_none {
        border-color: red;
        border-style: dotted;
        border-width: medium;
    }
    article.storyCard.visibility_calculating {
        border-color: orange;
        border-style: dotted;
        border-width: medium;
    }
    article.storyCard.visibility_shown {
        border-color: green;
        border-style: dotted;
        border-width: medium;
    }
`;
    document.head.appendChild(styleSheet);
}
