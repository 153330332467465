let setInlineStyles = function(text, inlineStyles = []) {
    var startTags = {};
    var endTags = {};
    var textTags = "";

    for (var i = 0; i < inlineStyles.length; i++) {
        var tags = getStyleTags(inlineStyles[i]["v"]);

        if ( startTags[inlineStyles[i]["s"]] === undefined ) {
            startTags[inlineStyles[i]["s"]] = {};
        }
        Object.assign(startTags[inlineStyles[i]["s"]], {[i]:tags.s});

        if ( endTags[inlineStyles[i]["e"]] === undefined ) {
            endTags[inlineStyles[i]["e"]] = {};
        }
        Object.assign(endTags[inlineStyles[i]["e"]], {[i]:tags.e});
    }

    for (var position = 0; position <= text.length; position++) {
        var skipEndTag = false;
        if (position in startTags) {
            if (position in endTags) {
                skipEndTag = true;

                Object.keys(endTags[position])
                    .forEach((key) => {
                        textTags += endTags[position][key];
                    });
            }

            Object.keys(startTags[position])
                .forEach((key) => {
                    textTags += startTags[position][key];
                });
        }

        if (!skipEndTag) {
            if (position in endTags) {
                Object.keys(endTags[position])
                    .reverse()
                    .forEach((key) => {
                        textTags += endTags[position][key];
                    });
            }
        }
        textTags += substr_utf8_bytes(text,position,1);
    }
    return textTags;
};

let getStyleTags = function(tag) {
    switch (tag) {
        case "bold":
            return {s: "<strong>", e: "</strong>"};

        case "italic":
            return {s: "<em>", e: "</em>"};

        case "lineThrough":
            return {s: "<del>", e: "</del>"};

        case "super":
            return {s: "<sup>", e: "</sup>"};

        case "sub":
            return {s: "<sub>", e: "</sub>"};

        default:
            return {};
    }
};

let encode_utf8 = function(s) {
    return decodeURI(encodeURIComponent(s));
};

let substr_utf8_bytes = function(str, startInBytes, lengthInBytes) {

    var resultStr = "";
    var startInChars = 0;

    for (var bytePos = 0; bytePos < startInBytes; startInChars++) {
        var ch = str.charCodeAt(startInChars);
        bytePos += (ch < 128) ? 1 : encode_utf8(str[startInChars]).length;
    }

    var end = startInChars + lengthInBytes - 1;

    for (var n = startInChars; startInChars <= end; n++) {
        var ch = str.charCodeAt(n);
        end -= (ch < 128) ? 1 : encode_utf8(str[n]).length;
        resultStr += str[n] !== undefined ? str[n] : "";
    }

    return resultStr;
};

export {setInlineStyles};
