import {setInlineStyles} from "./InlineStylesHelper";
export default class Flashnews
{
    constructor(endpointUrl) {
        if (!endpointUrl) {
            throw new TypeError("endpointUrl must be a non-empty string");
        }
        
        const FLASHNEWS_BOX_SELECTOR = ".flashnews";
        const FLASHNEWS_TITLE_SELECTOR = ".flashnews__title";
        const FLASHNEWS_BODY_SELECTOR = ".flashnews__body";

        try {
            fetch(endpointUrl)
                .then(response => {
                    if (response.ok && response.status === 200) {
                        return response.json();
                    } else {
                        return null;
                    }
                })
                .then(responseData => {
                    if (responseData !== null) {
                        responseData = responseData.data;
                        const boxNode = document.querySelector(FLASHNEWS_BOX_SELECTOR);
                        const titleNode = boxNode && boxNode.querySelector(FLASHNEWS_TITLE_SELECTOR);
                        const bodyNode = boxNode && boxNode.querySelector(FLASHNEWS_BODY_SELECTOR);

                        (titleNode && responseData.title) && (titleNode.innerHTML = setInlineStyles(responseData.title.text, responseData.title.inlineStyles));
                        (bodyNode && responseData.content) && (bodyNode.innerHTML = setInlineStyles(responseData.content.text, responseData.content.inlineStyles));

                        (boxNode && boxNode.classList.remove("display-none"));
                    }
                })
                .catch(reason => {
                    console.group("Flashnews");
                    console.debug("Endpoint %o", endpointUrl);
                    console.error("Raison %o", reason);
                    console.groupEnd();
                });
        } catch (error) {
            console.group("Flashnews");
            console.debug("Endpoint %o", endpointUrl);
            console.error("Error %o", error);
            console.groupEnd();
        }
    }
}
